.icon-svg-icon {
    color: #757575;
    fill: #757575;

    font-size: 18px;
    width: 18px;
    height: 18px;

    text-align: center;

    margin-right: 8px;
}
