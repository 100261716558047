.footer-container {
    background-color: #000000;
    margin-top: 72px;
    width: 100vw;
}

.footer-container-content {
    justify-content: center;
    color: #757575;
    display: flex;
    flex-direction: row;
}
.footer-container-content-mobile {
    justify-content: center;
    color: #757575;
    display: flex;
    flex-direction: column;
}

.footer-text-title {
    color: white;
    font-weight: 500;
    margin-bottom: 8px;
}

.footer-container-content-item {
    padding: 16px;
    max-width: 30%;
}
.footer-container-content-mobile {
    padding: 16px;
}

.footer-margin-bottom-8px {
    margin-bottom: 8px;
}

.footer-button-instagram {
    background-color: #d32f2f;
    border-radius: 24px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 12px;
    margin: 4px;
}
.footer-button-tiktok {
    background-color: #d71a60;
    border-radius: 24px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 12px;
    margin: 4px;
    margin-top: 8px;
}
