.form-button {
    background-color: #c58c2b;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin-top: 8px;
    padding: 8px 16px;
    text-align: center;
}

.form-field-container {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    width: calc(100% - 48px);

    position: relative;
}

.form-single {
    border: solid 1px #ececec;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
}
.form-single::-webkit-input-placeholder {
    /* Edge */
    font-size: 14px;
    font-weight: 400;
    color: #bdbdbd;
}
.form-single:-ms-input-placeholder {
    /* Internet Explorer */
    font-size: 14px;
    font-weight: 400;
    color: #bdbdbd;
}
.form-single::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #bdbdbd;
}
/* hide default icon calendar : https://stackoverflow.com/questions/57892727/hide-the-calendar-icon-in-google-chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.form-select-custom-arrow {
    /* hide select default arrow */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* custom arrow https://codepen.io/vkjgr/pen/VYMeXp */
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}

.form-text-error-label {
    color: red;
    font-size: 8px;
    font-weight: 400;

    position: absolute;
    right: 8px;
    bottom: 12px;
}

.form-text-header {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.form-text-label {
    margin: 0;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
}

@media (min-width: 640px) {
    .form-field-container {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
}

@media (min-width: 1280px) {
    .form-field-container {
        display: flex;
        flex-direction: column;
        width: 25%;
    }
    /* hide default icon calendar : https://stackoverflow.com/questions/57892727/hide-the-calendar-icon-in-google-chrome */
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: block;
        -webkit-appearance: block;
    }
}
