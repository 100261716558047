.testimonyitem-outer-container {
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.testimonyitem-container {
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 16px;
    width: calc(100% - 96px);

    /* position */
    position: relative;
    left: 24px;

    /* shadow */
    box-shadow: 0px 12px 24px 4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 12px 24px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 12px 24px 4px rgba(0, 0, 0, 0.1);
}

.testimonyitem-container-text {
    margin-left: 48px;
    flex: 1;
}

.testimonyitem-image {
    border-radius: 50%;
    height: 96px;
    width: 96px;

    /* position */
    position: absolute;
    left: -48px;
}

.testimonyitem-container-line-separator {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    margin-top: 8px;
    width: 100%;
}

.testimonyitem-line-separator {
    background-color: #d1d1d1;
    display: flex;
    flex: 1;
    height: 1px;
}

.testimonyitem-text-name {
    font-size: 14px;
    font-weight: 700;
}

.testimonyitem-text-description {
    font-size: 12px;
    font-weight: 400;
}

.testimonyitem-text-stars {
    color: #f8bb01;
    font-size: 14px;
}

@media (min-width: 640px) {
    .testimonyitem-container {
        width: 400px;
    }
}
