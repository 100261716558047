.imagecarousel-container {
    margin-bottom: 16px;
    overflow: hidden;
    width: 100vw;
}

.imagecarousel-image {
    /* ratio 16 : 9 */
    height: calc(100vw / 16 * 9);
    width: 100vw;
}

.imagecarousel-container-listimage {
    display: flex;
    flex-direction: row;
}

@media (min-width: 640px) {
    .imagecarousel-image {
        /* ratio 16 : 9 */
        height: calc(640px / 16 * 9);
        width: 640px;
    }
    .imagecarousel-container {
        display: flex;
        flex-direction: row;
        height: 360px;
        width: 640px;
    }
}
