.bullets-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4px;
}

.bullets-bullet {
    background-color: #e0e0e0;
    border-radius: 8px;
    height: 8px;
    margin: 4px;
    width: 8px;
}
