.app-container {
    align-items: center;
    background-color: #ffffff;
    color: #31373c;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.app-padding-container {
    width: 100vw;
}

@media (min-width: 640px) {
    .app-padding-container {
        width: 600px;
        align-self: center;
    }
}

@media (min-width: 1280px) {
    .app-padding-container {
        width: 1200px;
        align-self: center;
    }
}

.flex-column-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.touchable {
    cursor: pointer;
}
