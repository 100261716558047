.lineseparator-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 12px 16px;
    width: calc(100% - 32px);
}
.lineseparator-line {
    background-color: #31373c;
    display: flex;
    flex: 1;
    height: 1px;
}
.lineseparator-hamburger-container {
    cursor: pointer;
    flex-direction: column;
    height: 48px;
    margin: 0 16px;
    width: 24px;
}
